@import '../../global/styles/lib';

.related-publications {
    .banner {
        @include full-bleed;
        background: $most-blue;
        h4 {
            color: $white !important;
            font-size: 1.15rem !important;
            font-weight: 700;
            text-align: center;
            letter-spacing: 1rem;
            text-transform: uppercase;
            max-width: $entry-content-width;
            margin: 0 auto 2rem !important;
            padding: 0.5rem 0;
        } // h4
        
    } // .banner

    .publications {
        display: flex;
        flex-direction: column;
        margin-bottom: $block-tb-gutter;
        a {
            width: 100%;
            font-size: 1rem;
            text-align: center;
            &:first-child {
                margin-bottom: 1rem;
            } // &:first-child

        } // a

        @include breakpoint(medium up) {
            flex-direction: row;
            a {
                width: 50%;
                &:first-child {
                    margin-right: 3rem;
                    margin-bottom: 0;
                } // &:first-child
            } // a
        } // medium up

    } // .publications

} // .related-articles